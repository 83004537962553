import { Box, Flex, Text } from '@chakra-ui/layout';
import { Button, FlexProps, Icon } from '@chakra-ui/react';
import { TalentDiscoveryUser } from '@revelio/data-access';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Select } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import {
  addSourceWhaleCandidates,
  getSourceWhaleCampaigns,
} from './source-whale-api';
import { mapTDUsersToSWCandidates } from './source-whale.model';

type Option = {
  label: string;
  value: string;
};

export type SourceWhaleCampaign = {
  campaignId: string;
  campaignName: string;
};

interface SourceWhaleCandidateAdderProps extends FlexProps {
  apiKey: string;
  selectedUsers: TalentDiscoveryUser[];
  onAdded?: () => void;
}

export const SourceWhaleCandidateAdder = ({
  apiKey,
  selectedUsers,
  onAdded,
  ...rest
}: SourceWhaleCandidateAdderProps) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const isDisabled = !selectedOption;

  const { data, isLoading, error, isError } = useQuery({
    queryKey: ['source-whale-campaigns', apiKey],
    queryFn: () =>
      getSourceWhaleCampaigns({
        apiKey: apiKey,
      }),
    enabled: !!apiKey,
    retry: 2,
  });

  const {
    mutate: addCandidates,
    isPending: isAddingCandidates,
    error: addCandidatesError,
  } = useMutation({
    mutationFn: addSourceWhaleCandidates,
  });

  const options = useMemo(() => {
    return data?.campaigns.campaigns.map((campaign) => ({
      label: campaign.campaignName,
      value: campaign.campaignId,
    }));
  }, [data]);

  return (
    <Flex
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      width="245px"
      {...rest}
    >
      <Box width="100%">
        <Select<Option>
          options={options}
          placeholder="Select a campaign"
          isLoading={isLoading}
          onChange={(option) => setSelectedOption(option)}
          size="sm"
        />
        <Box position="relative">
          <Box position="absolute" bottom="-18px" right={0}>
            {isError && (
              <Text color="red.600" fontSize="xs">
                {error?.message}
              </Text>
            )}
            {addCandidatesError && (
              <Text color="red.600" fontSize="xs">
                {addCandidatesError?.message}
              </Text>
            )}
          </Box>
        </Box>
      </Box>
      <Button
        onClick={() => {
          if (!selectedOption) return;
          addCandidates({
            apiKey,
            candidates: mapTDUsersToSWCandidates({ users: selectedUsers }),
            campaignId: selectedOption.value,
          });
        }}
        disabled={!selectedOption}
        isLoading={isAddingCandidates}
        size="sm"
        colorScheme="gray"
        borderRadius="0" // Removed border radius
      >
        <Icon as={FiPlus} color={isDisabled ? 'gray.400' : ''} />
      </Button>
    </Flex>
  );
};
