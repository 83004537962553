import Select, { Props, components, OptionProps } from 'react-select';
import { SearchIcon } from '@chakra-ui/icons';

import { CustomOption } from './option';
import { defaultStyles } from './select-styles';

export type Option = {
  entity?: any;
  value: string;
  label: string;
  isDisabled?: boolean;
  disabled?: boolean;
};

export const SelectionListSelect = <IsMulti extends boolean = true>(
  props: Props<Option, IsMulti>
) => {
  return (
    <Select<Option, IsMulti>
      menuIsOpen
      placeholder="Search..."
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isClearable={false}
      backspaceRemovesValue={false}
      components={{
        DropdownIndicator: (props) => (
          <components.DropdownIndicator {...props}>
            <SearchIcon />
          </components.DropdownIndicator>
        ),
        Option: (props: OptionProps<Option, IsMulti>) => (
          <CustomOption {...props} />
        ),
      }}
      styles={{
        container: (base) => ({ ...base, ...defaultStyles.container }),
        control: (base) => ({ ...base, ...defaultStyles.control }),
        valueContainer: (base) => ({
          ...base,
          ...defaultStyles.valueContainer,
        }),
        input: (base) => ({ ...base, ...defaultStyles.input }),
        indicatorsContainer: (base) => ({
          ...base,
          ...defaultStyles.indicatorsContainer,
        }),
        indicatorSeparator: (base) => ({
          ...base,
          ...defaultStyles.indicatorSeparator,
        }),
        dropdownIndicator: (base) => ({
          ...base,
          ...defaultStyles.dropdownIndicator,
        }),
        menu: (base) => ({ ...base, ...defaultStyles.menu }),
        menuList: (base) => ({
          ...base,
          ...defaultStyles.menuList,
        }),
        option: (base, { isFocused, data }) => {
          return {
            ...base,
            ...defaultStyles.option,
            backgroundColor: isFocused ? '#F7FAFC' : 'transparent',
            borderColor: isFocused ? '#CBD5E0' : '#E2E8F0',
          };
        },
      }}
      {...props}
    />
  );
};
