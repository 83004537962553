/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Grid,
  Box,
  GridItem,
  VStack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  HStack,
  Icon,
  Spacer,
  Link,
  useToast,
} from '@chakra-ui/react';
import { useEffect$ } from '@ngneat/react-rxjs';
import {
  CTAInteractionTrackingEvents,
  pickKeys,
  setIsTourComplete,
  setIsVideoOpen,
  setVideoId,
  tourStore,
  TutorialTrackingEvents,
} from '@revelio/core';
import { catchError, distinctUntilChanged, OperatorFunction, tap } from 'rxjs';
import objectHash from 'object-hash';
import { WistiaPlayer } from '@wistia/wistia-player-react';
import { BiPlayCircle, BiPauseCircle, BiCheck } from 'react-icons/bi';
import mixpanel from 'mixpanel-browser';
import { useFreeTrialAlertModalState, videoMetadata } from '@revelio/layout';
import {
  ACCOUNT_PORTAL_BACKEND_ROOT,
  fetchAccountPortalbackend,
  useGetLoggedInUser,
} from '@revelio/auth';
import { isToday } from 'date-fns';
import { KeyValueResp, MetadataKey, User } from '@revelio/data-access';
import { isEmpty } from 'lodash';
import { useMutation } from 'urql';
import { UpdateUserMutation } from '../adminRewrite/userOperations';

const Tutorial = () => {
  const {
    loggedInUser,
    query: [{ fetching }],
    refetch: refetchUserData,
  } = useGetLoggedInUser();

  const [videos, setVideos] = useState<any>(videoMetadata);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [videoId, setVideoIdState] = useState<string | null>('0');

  const [videoPlaying, setVideoPlaying] = useState(false);

  const gridItemRef = useRef<HTMLDivElement>(null);

  const toast = useToast();
  const { name, email, metadata } = loggedInUser;
  const userWatchedIds: any = new Set(
    metadata?.find(
      (item: KeyValueResp | null) => item?.key === MetadataKey.StepsCompleted
    )?.value
  );
  const { days } = useFreeTrialAlertModalState();

  const [{ fetching: updatingUserMetaData }, updateUser] =
    useMutation(UpdateUserMutation);

  const mixpanelContactSales = () => {
    mixpanel.track(CTAInteractionTrackingEvents.CONTACT_SALES_CLICK, {
      days_left: days,
    });
  };

  const handleContactSales = async () => {
    mixpanelContactSales();

    const contactedSales = localStorage.getItem('contacted-sales') || '';
    if (!contactedSales || !isToday(new Date(contactedSales))) {
      await fetchAccountPortalbackend(
        `${ACCOUNT_PORTAL_BACKEND_ROOT}/users/zoho/contactsales`,
        {
          method: 'POST',
          body: JSON.stringify({ email: email, name: name }),
        }
      );
      localStorage.setItem('contacted-sales', new Date().toDateString());
      toast({
        title: 'E-mail sent!',
        description:
          "We've sent an e-mail to both you and a member of our Sales Team to connect.",
        status: 'success',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      toast({
        title: 'Thank you!',
        description:
          'We’ve notified a member of our Sales Team, who will reach out shortly.',
        status: 'success',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  useEffect$(() =>
    tourStore.pipe(
      pickKeys(['isVideoOpen', 'videoId']) as OperatorFunction<
        typeof tourStore.state,
        Pick<typeof tourStore.state, 'isVideoOpen' | 'videoId'>
      >,
      distinctUntilChanged((pre, cur) => objectHash(pre) == objectHash(cur)),
      tap((data) => {
        if (data.isVideoOpen) {
          onOpen();
        }
        if (data.videoId) {
          setVideoIdState(data.videoId);
        }
      }),
      catchError((e) => {
        return e;
      })
    )
  );

  useEffect(() => {
    if (videoId && isOpen) {
      setTimeout(() => {
        const lastCategory = videos[videos.length - 1];
        const isInLastCategory = lastCategory.videos.some(
          (v: any) => v.id === videoId
        );

        if (isInLastCategory && gridItemRef.current) {
          gridItemRef.current.scrollTo({
            top: gridItemRef.current.scrollHeight,
            behavior: 'instant',
          });
        }
      }, 150);
    }
  }, [videoId, videos, isOpen]);

  const handleVideoProgress = (
    percentWatched: number,
    currentVideoId: string
  ) => {
    if (percentWatched >= 0.1 && !userWatchedIds.has(currentVideoId)) {
      userWatchedIds.add(currentVideoId);

      const updatedStringWatchedIds = JSON.stringify(
        Array.from(userWatchedIds)
      );

      handleUpdateMetadata(loggedInUser, [
        {
          key: MetadataKey.StepsCompleted,
          value: updatedStringWatchedIds,
        },
      ]);

      const currentVideoName = getVideoDataById(currentVideoId)?.name;

      mixpanel.track(TutorialTrackingEvents.WATCH_TUTORIAL_VIDEO, {
        video_id: currentVideoId,
        video_name: currentVideoName,
      });
    }
  };

  const getVideoDataById = (Id: any) => {
    for (const category of videoMetadata) {
      const video = category.videos.find((v: any) => v.id === Id);
      if (video) {
        return video;
      }
    }
    console.error('Video not found for Id:', videoId);
    return null;
  };

  const handleUpdateMetadata = (
    loggedInUser: User = {},
    newMeta: KeyValueResp[]
  ) => {
    const metaFieldsToExclude = [MetadataKey.ExpirationDate];

    return new Promise((resolve, reject) => {
      if (isEmpty(loggedInUser)) {
        reject('No logged in user provided');
      }

      const newMetaKeys = newMeta.map((item) => item.key);

      const currentMetaObjects = (loggedInUser.metadata || []).filter(
        (item: KeyValueResp | null) =>
          !newMetaKeys.includes(item?.key) &&
          !metaFieldsToExclude.includes(item?.key as MetadataKey)
      );

      const updatedMeta = [...currentMetaObjects, ...newMeta];

      updateUser({
        id: loggedInUser?.id as string,
        metadata: updatedMeta,
      })
        .then((res) => {
          // update user object on update success
          refetchUserData();
          resolve('User updated');
        })
        .catch((err) => {
          reject('Something went wrong');
        });
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      onCloseComplete={() => {
        tourStore.update(setIsVideoOpen(false));
        tourStore.update(setIsTourComplete(true));
      }}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay bgColor="rgba(0,0,0,0.7)" />
      <ModalContent maxW="1000px">
        <ModalHeader color="navyBlue.500">Tutorials</ModalHeader>
        <ModalCloseButton
          data-testId="tutorial-modal-close-btn"
          color="navyBlue.500"
          _focus={{ boxShadow: 'none' }}
        />
        <ModalBody p="0px 20px 20px 20px">
          <Grid templateColumns="1fr 300px" gap={4} h="100%">
            <GridItem>
              <Box data-testId="tutorial-video-player" w="644px" h="363px">
                <WistiaPlayer
                  mediaId={getVideoDataById(videoId)?.mediaId || ''}
                  autoplay={true}
                  onPercentWatchedChange={({ detail: { percentWatched } }) => {
                    if (videoId) {
                      handleVideoProgress(percentWatched, videoId);
                    }
                  }}
                  onPlay={() => setVideoPlaying(true)}
                  onPause={() => setVideoPlaying(false)}
                  onEnded={() => {
                    console.log('video ended');
                    const currentVideoName = getVideoDataById(videoId)?.name;

                    mixpanel.track(TutorialTrackingEvents.END_TUTORIAL_VIDEO, {
                      video_id: videoId,
                      video_name: currentVideoName,
                    });
                  }}
                />
              </Box>
            </GridItem>
            <GridItem
              ref={gridItemRef}
              bgColor="white"
              overflow="auto"
              position="relative"
            >
              <Accordion
                h="300px"
                allowToggle
                allowMultiple
                defaultIndex={[0, 1, 2]}
              >
                {videos.map(({ category, videos }: any, topIndex: any) => (
                  <AccordionItem
                    key={`${category}`}
                    border="none"
                    borderTop={topIndex > 0 ? '1px solid' : 'none'}
                    borderColor="navyBlue.50"
                  >
                    <h2>
                      <AccordionButton
                        fontWeight={600}
                        fontSize={14}
                        color="navyBlue.500"
                      >
                        <Box as="span" flex="1" textAlign="left">
                          {category}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      px={1}
                      py={2}
                      borderTop="1px solid"
                      borderColor="navyBlue.50"
                    >
                      <VStack alignItems="flex-start" gap={0.5}>
                        {videos.map(({ name, id, duration }: any) => (
                          <Box
                            px={3}
                            py={2}
                            bgColor={id === videoId ? 'blue.50' : 'white'}
                            w="full"
                            rounded="md"
                            _hover={{
                              bgColor: id === videoId ? 'blue.50' : 'gray.100',
                              opacity: id === videoId ? 0.7 : 1,
                            }}
                            cursor="pointer"
                            onClick={() => tourStore.update(setVideoId(id))}
                          >
                            <HStack alignItems="center" gap={1}>
                              <Icon
                                as={
                                  id === videoId && videoPlaying
                                    ? BiPauseCircle
                                    : BiPlayCircle
                                }
                                boxSize={5}
                                color={
                                  id === videoId ? 'blue.500' : 'navyBlue.500'
                                }
                              />
                              <Text
                                fontSize={14}
                                color={
                                  id === videoId ? 'blue.500' : 'navyBlue.500'
                                }
                              >
                                {name}
                              </Text>
                              <Spacer />
                              {userWatchedIds.has(id as never) ? (
                                <Icon
                                  as={BiCheck}
                                  color="green.500"
                                  boxSize={5}
                                />
                              ) : (
                                <Text fontSize={10} opacity={0.7}>
                                  {duration}
                                </Text>
                              )}
                            </HStack>
                          </Box>
                        ))}
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </GridItem>
          </Grid>
          <Box pt={3}>
            <Text fontSize="xl" fontWeight={600}>
              {getVideoDataById(videoId)?.name}
            </Text>
            <Text opacity={0.7}>
              {getVideoDataById(videoId)?.description}{' '}
              {videoId === '10' && (
                <span>
                  {` `}
                  <Link
                    onClick={handleContactSales}
                    color="green.500"
                    fontWeight={600}
                  >
                    Contact Sales
                  </Link>{' '}
                  to get started.
                </span>
              )}
            </Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Tutorial;
