import { Box } from '@chakra-ui/layout';
import { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import { SelectionListControls } from '../SelectionListControls';
import { Option, SelectionListSelect } from './selection-list-select';
import { MultiValue, Props } from 'react-select';

export type SelectionListControls = {
  value: SelectionListValue;
  setValue: React.Dispatch<React.SetStateAction<SelectionListValue>>;
  clearSelections: () => void;
};
export type SelectionListValue = MultiValue<Option>;

export type SelectionListProps = {
  defaultValue?: SelectionListValue;
  close?: () => void;
  onChangeOverride?: (
    selectedOptions: SelectionListValue,
    { value, setValue, clearSelections }: SelectionListControls
  ) => void;
  submitLabel?: string;
  submit: (value: SelectionListValue) => void;
};

export const SelectionList = ({
  options,
  defaultValue = [],
  close,
  onChangeOverride,
  submitLabel = 'Add',
  submit,
}: Props<Option, true> & SelectionListProps) => {
  const [value, setValue] = useState<SelectionListValue>(defaultValue);

  const prevDefaultValue = useRef(defaultValue);
  useEffect(() => {
    if (!isEqual(prevDefaultValue.current, defaultValue)) {
      prevDefaultValue.current = defaultValue;
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const clearSelections = () => {
    setValue([]);
  };

  const handleAddSelections = () => {
    submit(value);
    close && close();
  };

  const onChange = onChangeOverride
    ? (selectedOptions: SelectionListValue) =>
        onChangeOverride(selectedOptions, { value, setValue, clearSelections })
    : (selectedOptions: SelectionListValue) => {
        setValue(selectedOptions);
      };

  return (
    <Box>
      <SelectionListSelect
        options={options}
        value={value}
        onChange={onChange}
        isMulti
      />
      <SelectionListControls
        onClear={clearSelections}
        onSubmit={handleAddSelections}
        onClose={close}
        submitText={submitLabel}
      />
    </Box>
  );
};
