/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  Text,
  Icon,
  Flex,
  Image,
  Box,
  Button,
  PopoverCloseButton,
  useDisclosure,
  Link,
  useToast,
} from '@chakra-ui/react';
import {
  CTAInteractionTrackingEvents,
  PopoverTrigger,
  setIsVideoOpen,
  setVideoId,
  tourStore,
} from '@revelio/core';
import { FiBookOpen } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import { useEffect } from 'react';
import { useFreeTrialAlertModalState } from '../../hooks/useFreeTrialAlertModalState';
import overviewImage from './tutorial-thumbnails/tutorial-overview.jpg';
import compositionImage from './tutorial-thumbnails/tutorial-compositions.jpg';
import transitionsImage from './tutorial-thumbnails/tutorial-transitions.jpg';
import postingsImage from './tutorial-thumbnails/tutorial-postings.jpg';
import payImage from './tutorial-thumbnails/tutorial-pay.jpg';
import sentimentImage from './tutorial-thumbnails/tutorial-sentiment.jpg';
import discoveryImage from './tutorial-thumbnails/tutorial-discovery.jpg';
import talentDiscoveryImage from './tutorial-thumbnails/tutorial-talent-discovery.jpg';
import reportBuilderImage from './tutorial-thumbnails/tutorial-report-builder.jpg';
import dataBuilderImage from './tutorial-thumbnails/tutorial-data-builder.jpg';
import dataEnrichmentImage from './tutorial-thumbnails/tutorial-data-enrichment.jpg';
import {
  ACCOUNT_PORTAL_BACKEND_ROOT,
  fetchAccountPortalbackend,
  useGetLoggedInUser,
} from '@revelio/auth';
import mixpanel from 'mixpanel-browser';
import { isToday } from 'date-fns';

export const videoMetadata: any = [
  {
    category: 'Dashboard Pages',
    videos: [
      {
        name: 'Overview',
        description:
          'Analyze workforces through the lens of Companies, Geographies, Roles, and People.',
        duration: '2 mins',
        thumbnail: overviewImage,
        mediaId: 'vxukqgd7v9',
        paths: ['/'],
        id: '0',
      },
      {
        name: 'Compositions',
        description:
          'Benchmark workforces and gather competitive intelligence.',
        duration: '1 min',
        thumbnail: compositionImage,
        mediaId: 'zhuzvcgm1n',
        paths: [
          '/company/compositions',
          '/geography/compositions',
          '/role/compositions',
        ],
        id: '1',
      },
      {
        name: 'Transitions',
        description: 'See where talent is coming from and going to.',
        duration: '1 min',
        thumbnail: transitionsImage,
        mediaId: 'l44r5f542f',
        paths: [
          '/company/transitions',
          '/geography/transitions',
          '/role/transitions',
        ],
        id: '2',
      },
      {
        name: 'Job Postings',
        description: 'Analyze demand for roles and skill.',
        duration: '1 min',
        thumbnail: postingsImage,
        mediaId: 'etp6wqmphy',
        paths: ['/company/postings', '/geography/postings', '/role/postings'],
        id: '3',
      },
      {
        name: 'Pay',
        description: 'Benchmark salary across companies, locations, and roles.',
        duration: '2 mins',
        thumbnail: payImage,
        mediaId: 'uuzhueff2l',
        paths: ['/company/pay', '/geography/pay', '/role/pay'],
        id: '4',
      },
      {
        name: 'Sentiment',
        description:
          'Reveal factors of hiring and attrition for specific workforces.',
        duration: '1 min',
        thumbnail: sentimentImage,
        mediaId: 'c5pz9z6vca',
        paths: [
          '/company/sentiment',
          '/geography/sentiment',
          '/role/sentiment',
        ],
        id: '5',
      },
      {
        name: 'Talent Discovery',
        description:
          'Discover individual candidates matching your unique search criteria.',
        duration: '3 mins',
        thumbnail: talentDiscoveryImage,
        mediaId: '5o614nymsh',
        paths: ['/talent-discovery'],
        id: '6',
      },
      {
        name: 'Discovery',
        description:
          'Discover companies, roles, and talent markets by growth rate, attrition rate, skill density, and more.',
        duration: '3 mins',
        thumbnail: discoveryImage,
        mediaId: 'xffre1dkvc',
        paths: ['/company/screener', '/geography/screener', '/role/screener'],
        id: '7',
      },
    ],
  },
  {
    category: 'Custom Reports & Datasets',
    videos: [
      {
        name: 'Report Builder',
        description:
          'Build a custom report from our template library of workforce planning use cases.',
        duration: '1 min',
        thumbnail: reportBuilderImage,
        mediaId: '6dou1fxe9r',
        paths: ['/report-builder/report_type', '/reports', '/reports/'],
        id: '8',
      },
      {
        name: 'Data Builder',
        description:
          'Create a custom dataset for your analysis. This advanced feature is available for trials with our sales team.',
        duration: '4 mins',
        thumbnail: dataBuilderImage,
        mediaId: 'itlzpu7eyb',
        paths: ['/deliverables/dataset', '/deliverables/status'],
        id: '9',
      },
      {
        name: 'Data Enrichment',
        description:
          'Enrich your internal data. This advanced feature is available for trials with our sales team.',
        duration: '1 min',
        thumbnail: dataEnrichmentImage,
        mediaId: 'oqbg209tqf',
        paths: ['/deliverables/data-enrichment'],
        id: '10',
      },
    ],
  },
];

/* eslint-disable-next-line */
export interface TutorialButtonProps {}

const TutorialButton = (props: TutorialButtonProps) => {
  const { isOpen, onToggle, onClose, onOpen } = useDisclosure();
  const location = useLocation();
  const { showTrialChip } = useFreeTrialAlertModalState();

  const toast = useToast();
  const { loggedInUser } = useGetLoggedInUser();
  const { name, email } = loggedInUser;
  const { days } = useFreeTrialAlertModalState();

  const mixpanelContactSales = () => {
    mixpanel.track(CTAInteractionTrackingEvents.CONTACT_SALES_CLICK, {
      days_left: days,
    });
  };

  const handleContactSales = async () => {
    mixpanelContactSales();

    const contactedSales = localStorage.getItem('contacted-sales') || '';
    if (!contactedSales || !isToday(new Date(contactedSales))) {
      await fetchAccountPortalbackend(
        `${ACCOUNT_PORTAL_BACKEND_ROOT}/users/zoho/contactsales`,
        {
          method: 'POST',
          body: JSON.stringify({ email: email, name: name }),
        }
      );
      localStorage.setItem('contacted-sales', new Date().toDateString());
      toast({
        title: 'E-mail sent!',
        description:
          "We've sent an e-mail to both you and a member of our Sales Team to connect.",
        status: 'success',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      toast({
        title: 'Thank you!',
        description:
          'We’ve notified a member of our Sales Team, who will reach out shortly.',
        status: 'success',
        duration: 6000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const getVideoDataFromPath = (pathname: string) => {
    for (const category of videoMetadata) {
      const video = category.videos.find((v: any) =>
        v.paths.includes(pathname)
      );
      if (video) {
        return { ...video };
      }
    }
    console.error('No video found for this pathname:', pathname);
    return null;
  };

  useEffect(() => {
    if (showTrialChip) {
      onOpen();
    }
  }, [showTrialChip, location.pathname, onOpen]);

  useEffect(() => {
    if (
      showTrialChip &&
      isOpen &&
      !['/deliverables/dataset', '/deliverables/data-enrichment'].includes(
        location.pathname
      )
    ) {
      const timer = setTimeout(() => {
        onClose();
      }, 6000);

      return () => clearTimeout(timer);
    }
    return () => null;
  }, [showTrialChip, isOpen, onClose, location.pathname]);

  const handleClick = () => {
    onClose();
    tourStore.update(setVideoId(videoData?.id));
    tourStore.update(setIsVideoOpen(true));
  };

  const videoData = location.pathname.startsWith('/reports/')
    ? getVideoDataFromPath('/reports/')
    : getVideoDataFromPath(location.pathname);

  return (
    <Popover
      placement="bottom-end"
      variant="with-shadow"
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <IconButton
          size="sm"
          variant="ghost"
          aria-label="open notifications"
          colorScheme="navyBlueAlpha"
          icon={<Icon as={FiBookOpen} boxSize={5} color="#8F9CB2" />}
          onClick={onToggle}
        />
      </PopoverTrigger>
      <PopoverContent
        sx={{
          _focus: { boxShadow: 'none', outline: 'none' },
          _focusVisible: { boxShadow: 'none', outline: 'none' },
        }}
      >
        <PopoverBody maxH="360px" pb={3}>
          <Flex direction="column" gap={2} pt={6}>
            <Box
              cursor="pointer"
              _hover={{ opacity: 0.8 }}
              onClick={handleClick}
            >
              <Image
                src={videoData?.thumbnail}
                alt="video thumbnail"
                rounded="xl"
              />
            </Box>
            <Text fontSize="md" fontWeight="semibold" px={1} lineHeight="16px">
              {videoData?.name}
            </Text>
            <Text fontSize="sm" px={1} lineHeight="16px">
              {videoData?.description}
              {videoData?.id === '10' && (
                <span>
                  {` `}
                  <Link
                    onClick={handleContactSales}
                    color="green.500"
                    fontWeight={600}
                  >
                    Contact Sales
                  </Link>{' '}
                  to get started.
                </span>
              )}
            </Text>
            <Button
              size="lg"
              mt={2}
              colorScheme="navyBlue"
              onClick={handleClick}
            >
              Watch Tutorial
              <span
                style={{
                  fontSize: '16px',
                  opacity: 0.7,
                  paddingLeft: '6px',
                  fontWeight: 500,
                }}
              >
                2:35
              </span>
            </Button>
          </Flex>
        </PopoverBody>
        <PopoverCloseButton />
      </PopoverContent>
    </Popover>
  );
};

export default TutorialButton;
