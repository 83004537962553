import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, noop } from 'lodash';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import {
  ActionModalControlPanel,
  ActionModalControlPanelProps,
} from '@revelio/core';
import {
  SelectionCategories,
  SelectionListIdNames,
  Tree,
  TreeItem,
  TreeMethodHandle,
  TreeType,
} from '@revelio/filtering';

import {
  ReportEntityCompanyEditor,
  ReportEntityCompanyEditorHandle,
  ReportEntityCompanyEditorProps,
} from './report-entity-company-editor';
import {
  AdditionalReportSelectionCategories,
  ReportSelectionCategories,
} from '../entity-configuration/utils';
import { REPORT_BUILDER_SELECTION_LIST_PARENT_MAP } from '../report-builder-selection-list-parent-map';
import { repvueRCIDsFilter } from './repvue-rcids';

export interface ReportEntitySelectionsModalProps
  extends Partial<ActionModalControlPanelProps> {
  headerLabel?: string;
  selectionLists?: ReportSelectionCategories[];
  isOpen: boolean;
  submitHandler?: (selections: Record<string, TreeItem<string>>) => void;
  closeOnSubmit?: boolean;
  initialSelections?: Record<string, TreeItem>;
  limit?: number;
  isNested?: boolean;
}

export const ReportEntitySelectionsModal = ({
  headerLabel = '',
  selectionLists = [],
  isOpen,
  onClose = noop,
  onCancel,
  submitHandler,
  submitText,
  closeOnSubmit = true,
  initialSelections = {},
  limit,
  isNested,
}: ReportEntitySelectionsModalProps) => {
  const [selections, setSelections] =
    useState<Record<string, TreeItem>>(initialSelections);
  const searchInputRef = useRef(null);

  const treeRef = useRef<TreeMethodHandle>();
  const companyEditorRef = useRef<ReportEntityCompanyEditorHandle>(null);
  const shouldUseReportEntityCompanyEditor = selectionLists?.some(
    (list) =>
      list === SelectionCategories.COMPANY ||
      list === AdditionalReportSelectionCategories.COMPANY_REPVUE
  );
  const shouldUseRepvue = selectionLists?.includes(
    AdditionalReportSelectionCategories.COMPANY_REPVUE
  );
  const normalizedSelectionLists = useMemo(() => {
    return selectionLists.map((s) =>
      s === AdditionalReportSelectionCategories.COMPANY_V1 ||
      s === AdditionalReportSelectionCategories.COMPANY_REPVUE
        ? SelectionCategories.COMPANY
        : s
    );
  }, [selectionLists]);

  const handleReset = () => {
    treeRef.current?.handleClearSelections?.();
    companyEditorRef.current?.handleClearSelections();
  };

  const handleSubmit = () => {
    submitHandler?.(selections);
    setSelections({});

    if (closeOnSubmit) {
      onClose();
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setSelections(initialSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const showSelected = (limit || 0) === 1;

  const header = (
    <Flex alignItems="center">
      <Text>{`Select ${headerLabel}`}</Text>
      {showSelected && (
        <Text
          fontSize={14}
          color="gray.600"
          ml={2}
        >{`(${Object.values(selections).length || 0}/${limit} Selected)`}</Text>
      )}
    </Flex>
  );

  const onCompanySearchSelections = useCallback<
    ReportEntityCompanyEditorProps['onSelectionsValue']
  >(
    (items) => {
      const mappedSelections: Record<string, TreeItem> = {};
      const selectionListId = shouldUseRepvue
        ? AdditionalReportSelectionCategories.COMPANY_REPVUE
        : SelectionCategories.COMPANY;
      Object.keys(items).forEach((key) => {
        const companyItem = items[key];
        const id = `${selectionListId}.${companyItem.rcid}`;
        mappedSelections[id] = {
          id: id,
          item: {
            id: companyItem.rcid,
            shortName: companyItem.primary_name,
            longName: companyItem.primary_name,
            label: companyItem.primary_name,
            ...companyItem,
          },
          children: [],
          parentId: undefined,
          selectionListId: selectionListId as SelectionListIdNames,
        };
      });
      setSelections(mappedSelections);
    },
    [shouldUseRepvue]
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      initialFocusRef={searchInputRef}
    >
      <ModalOverlay />
      <ModalContent minHeight="530px">
        <ModalHeader
          borderBottom="1px solid #E5EBF1"
          fontSize="17px"
          fontWeight="600"
          py="12px"
        >
          {header}
        </ModalHeader>
        <ModalCloseButton size="sm" />
        <ModalBody py="16px">
          {shouldUseReportEntityCompanyEditor ? (
            <ReportEntityCompanyEditor
              filterFn={shouldUseRepvue ? repvueRCIDsFilter : undefined}
              searchInputRef={searchInputRef}
              treeHeight={315}
              ref={companyEditorRef}
              onSelectionsValue={onCompanySearchSelections}
              limit={limit ?? 1}
            />
          ) : (
            <Tree
              forwardedRef={treeRef}
              selectionLists={normalizedSelectionLists}
              limit={limit}
              setTempSelections={(s) => {
                setSelections(s);
              }}
              defaultSelectedItemIds={Object.keys(initialSelections)}
              height={315}
              nestingTreeType={
                isNested ? TreeType.BREADCRUMB_NESTED : undefined
              }
              selectionListParentMap={REPORT_BUILDER_SELECTION_LIST_PARENT_MAP}
            />
          )}
        </ModalBody>
        <ModalFooter borderTop="1px solid #E5EBF1">
          <ActionModalControlPanel
            submitText={submitText}
            onClose={onClose}
            onCancel={onCancel}
            onSubmit={handleSubmit}
            onReset={handleReset}
            submitIsDisabled={isEmpty(selections)}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
